<template>
  <div class="add-to-cart">
    <!-- In Cart But out of Stock -->
    <VyButton
      type="button"
      v-if="
        (isOutOfStock || (manageInventory && maxQtyFinal <= 0)) &&
        cartQty(id) > 0
      "
      label="Remove"
      class="button--muted button--danger button--sm button--rounded"
      @click.native="changeQty(0)"
    />

    <!-- Out of Stock -->
    <VyButton
      type="button"
      class="button--muted button--gray button--sm button--rounded"
      v-else-if="isOutOfStock || (manageInventory && maxQtyFinal <= 0)"
      badge
      label="Out of Stock"
    />

    <!-- Not Available -->
    <VyButton
      type="button"
      class="button--gray button--muted button--sm button--rounded"
      v-else-if="notAvailable"
      badge
      label="Not Available"
    />

    <!-- Manage Quantity -->
    <template v-else-if="cartQty(id) > 0">
      <div v-if="allowQty">
        <!--  +1 max qty will trigger the qty alert so need that -->
        <CustomNumber
          :value="cartQty(id)"
          :min="0"
          :max="maxQtyFinal + 1"
          @input="changeQty($event)"
          v-slot="{ value, set }"
        >
          <input
            ref="qty"
            :value="value"
            type="number"
            :min="1"
            :max="maxQtyFinal"
            step="1"
            maxlength="3"
            class="add-to-cart__input vp-bg-gray-200 vp-text-sm vp-rounded vp-text-center vp-w-8 vp-mx-1"
            @input="qtyInput($event.target.value, set)"
            @blur="qtyBlur($event, value, set)"
          />
        </CustomNumber>
        <p v-if="maxQtyReached" class="vp-text-warning-500 vp-text-xs vp-mt-1">
          Available Stock: {{ maxQty }}
        </p>
      </div>

      <!-- Remove -->
      <VyButton
        v-else
        type="button"
        class="button--danger button--muted button--sm button--rounded"
        label="Remove"
        @click.native="changeQty(0)"
      />
    </template>

    <!-- Add To Cart -->
    <VyButton
      v-else
      type="button"
      class="button--primary button--muted button--sm button--rounded"
      label="Add to Cart"
      @click.native="add({ id: id })"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import CustomNumber from "./custom-number.vue";

export default {
  components: {
    CustomNumber,
  },
  props: {
    id: [Number, String],
    admin: Boolean,
    isOutOfStock: Boolean,
    manageInventory: Boolean,
    notAvailable: Boolean,
    allowQty: {
      type: Boolean,
      default: true,
    },
    maxQty: {
      type: Number,
      default: 999,
    },
  },

  data() {
    return {
      maxQtyReached: false,
    };
  },

  watch: {
    id() {
      this.maxQtyReached = false;
    },
  },

  computed: {
    ...mapGetters({
      cartQty: "cart/cartQty",
    }),

    maxQtyFinal() {
      if (!this.manageInventory || this.maxQty == null) {
        return 999;
      }
      return this.maxQty;
    },
  },

  methods: {
    ...mapMutations({
      add: "cart/add",
      remove: "cart/remove",
      updateQty: "cart/updateQty",
    }),

    changeQty(value) {
      if (value > this.maxQtyFinal) {
        this.maxQtyReached = true;
      } else if (value <= 0) {
        this.remove(this.id);
      } else {
        this.maxQtyReached = false;
        this.updateQty({
          id: this.id,
          value: value,
        });
      }
    },

    qtyBlur(e, value) {
      const qty = e.target.value;
      if (!qty) {
        e.target.value = value;
      }
    },

    qtyInput(value, set) {
      if (value == "") return;
      let newValue;
      if (value >= 0 && value <= this.maxQtyFinal) {
        newValue = Math.abs(parseInt(value));
      } else {
        newValue = 1;
      }
      //Update UI field to skip non integer value
      this.$refs.qty.value = newValue;
      //Actually set value
      set(newValue);
    },
  },
};
</script>

<style>
.add-to-cart__input {
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.add-to-cart {
  .field__footer {
    display: none;
  }
  .field__input {
    margin-top: 0;
  }
}
</style>
